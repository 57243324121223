body {
  background: url("./three.jpg");
  background-size: cover; /* Use 'cover' to make the background image cover the entire screen */
  background-position: center; /* Center the background image */
  margin: 0; /* Remove default body margin */
  padding: 0; /* Remove default body padding */
}

.background-transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden; /* Ensure that the background images don't overflow the viewport */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0; /* Initially set opacity to 0 */
  transition: opacity 1s ease-in-out; /* Apply transition effect */
  z-index: -1; /* Ensure the images are behind other content */
}

.background-image.visible {
  opacity: 1; /* When the visible class is added, set opacity to 1 */
}

#app-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the loader appears above other content */
}

.loader-content {
  text-align: center;
}

.loader-text {
  font-size: 20px;
  margin-bottom: 20px;
}
