.maintenance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f9;
  color: #333;
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

h1 {
  margin-bottom: 16px;
}

p {
  margin-bottom: 30px;
}

.animation-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 20px;
  height: 20px;
  background-color: #555;
  border-radius: 50%;
  margin: 5px;
  animation: bounce 1.4s infinite ease-in-out;
}

.circle:nth-child(2) {
  animation-delay: -0.32s;
}

.circle:nth-child(3) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
